import { useState, useEffect } from "react";

const STATUS_WAITING_AUTHOR = 0;
const STATUS_WAITING_ADMIN = 1;
const STATUS_CLOSED = 2;

const ticketStates = {
    [STATUS_CLOSED]: "Geschlossen",
    [STATUS_WAITING_AUTHOR]: "Warte auf Ticketauthor",
    [STATUS_WAITING_ADMIN]: "Warte auf Administrator",
};

const Ticketarchiv = () => {
    const [tickets, setTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("/api/tickets", {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Fehler beim Laden der Tickets");
                }
                return response.json();
            })
            .then((data) => {
                setTickets(data.filter(ticket => ticket.status == STATUS_CLOSED));
            })
            .catch((err) => {
                console.error(err);
                setError("Fehler beim Laden der Tickets.");
            })
            .finally(() => setLoading(false));
    }, []);

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        return date.toLocaleString();
    };

    const closePopup = () => setSelectedTicket(null);

    if (loading) {
        return <div>Lädt...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="p-4">
            {tickets.length === 0 ? (
                <div className="text-center text-gray-500">Keine Tickets gefunden.</div>
            ) : (
                tickets.map((ticket) => (
                    <div
                        key={ticket.id}
                        className="border border-gray-300 rounded-lg p-4 mb-4 shadow-md relative cursor-pointer"
                        onClick={() => setSelectedTicket(ticket)}
                    >
                        <h3 className="text-lg font-bold text-gray-800 mb-2">{ticket.title || "Kein Titel"}</h3>
                        <p className="text-gray-600 mb-4">{ticket.content || "Keine Beschreibung"}</p>
                        <div className="absolute top-2 right-2">
                            <span className="bg-gray-200 text-gray-800 text-sm font-medium px-2 py-1 rounded">
                                {ticketStates[ticket.status] || "Unbekannt"}
                            </span>
                        </div>
                        <div className="mt-2">
                            <span className="inline-block bg-gray-100 text-gray-500 text-xs px-3 py-1 rounded-full">
                                {formatDateTime(ticket.creationtime)}
                            </span>
                        </div>
                    </div>
                ))
            )}

            {selectedTicket && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg relative">
                        <button
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                            onClick={closePopup}
                        >
                            &times;
                        </button>
                        <h3 className="text-2xl font-bold mb-4">{selectedTicket.title || "Kein Titel"}</h3>
                        <p className="text-gray-700 mb-4">{selectedTicket.content || "Keine Beschreibung"}</p>
                        <p className="text-sm text-gray-500">
                            Status: {ticketStates[selectedTicket.status] || "Unbekannt"}
                        </p>
                        <p className="text-sm text-gray-400">
                            Erstellt am: {formatDateTime(selectedTicket.creationtime)}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Ticketarchiv;
