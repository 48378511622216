import { useState, useEffect } from "react";

const Benutzer = () => {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        fetch('/api/user/', {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data => setUserList(data))
        .catch(error => console.error('Error fetching user data:', error));
    }, []);

    return (
        <>
            <div className="flex flex-wrap flex-row mb-4 gap-4">
                <button onClick={() => alert('TODO')} className="border shadow bg-zinc-50 rounded-lg p-2">Filtern</button>
                <button onClick={() => alert('TODO')} className="border shadow bg-zinc-50 rounded-lg p-2 ">Hinzufügen...</button>
            </div>
            <div className="flex flex-wrap gap-4">
                {userList.map((user, userIndex) => (
                    <div key={userIndex} className="border rounded-lg p-6 w-full max-w-xs bg-white shadow-md">
                        <h3 className="text-xl font-semibold mb-2">
                            {user.username}
                        </h3>
                        <div className="flex flex-wrap gap-1">
                            {user.roles.map((role, roleIndex) => (
                                <span 
                                    key={roleIndex} 
                                    className="border rounded-full px-2 py-1 text-xs font-semibold"
                                >
                                    {role.name}
                                </span>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Benutzer;
