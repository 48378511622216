import React from "react";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import SideNavigation from "../SideNavigationPage";
import Ticketuebersicht from "./Ticketuebersicht";
import Ticketarchiv from "./Ticketarchiv";

const Ticketsystem = () => {
    const routes = [
        {
            path: '/',
            display: null,
            element: <Navigate to="ticketuebersicht" replace />
        },
        {
            path: 'ticketuebersicht',
            display: 'Übersicht',
            element: <Ticketuebersicht />
        },
        {
            path: 'ticketarchiv',
            display: 'Archiv',
            element: <Ticketarchiv />
        }
    ]

    return (
        <div className="flex">
            <SideNavigation routes={routes} />
            <div className="flex-1 p-4">
                <Routes>
                    {routes.map((route, routeIndex) => {
                        return <Route key={routeIndex} path={route.path} element={route.element} />
                    })}
                </Routes>
            </div>
        </div>
    );
};

export default Ticketsystem;
