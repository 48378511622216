import React from "react";
import { NavLink } from "react-router-dom";

const SideNavigation = ({ routes }) => {
    return (
        <aside className="top-0 left-0 w-36 h-screen transition-transform -translate-x-full sm:translate-x-0">
            <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
                <ul>
                    {routes.filter(route => route.display != null).map((route, routeIndex) => (
                        <li key={routeIndex}>
                            <NavLink
                                id={`adminpanel-navigation-${routeIndex}`}
                                to={route.path}
                                className={({ isActive }) =>
                                    `flex items-center p-2 text-gray-900 rounded-lg group ${isActive ? 'bg-gray-200' : 'hover:bg-gray-100'}`
                                }
                            >
                                {route.display}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    );
}

export default SideNavigation;
