import { useState, useEffect } from "react";

const Raeume = () => {
    const [roomList, setRoomList] = useState([]);
    const [filteredRaeume, setFilteredRaeume] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isAddRoomOpen, setIsAddRoomOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [activeFilters, setActiveFilters] = useState({
        beamer: null,
        seats: '',
        computers: '',
    });

    useEffect(() => {
        reloadRooms();
    }, []);

    const reloadRooms = async () => {
        try {
            const response = await fetch('/api/rooms/', {
                method: 'GET',
            });

            const json = await response.json()
            setRoomList(json);
            setFilteredRaeume(json);
        } catch(err) {
            console.error('Error fetching room data:', err)
        }
    }

    useEffect(() => {
        const filtered = roomList.filter(room => {
            return (
                (activeFilters.beamer === null || room.beamer === activeFilters.beamer) &&
                (activeFilters.seats === '' || room.seats >= activeFilters.seats) &&
                (activeFilters.computers === '' || room.computers >= activeFilters.computers)
            );
        });

        setFilteredRaeume(filtered);
    }, [activeFilters, roomList]);

    const isFilterApplied = () => {
        return !(
            activeFilters.beamer === null &&
            activeFilters.seats === '' &&
            activeFilters.computers === ''
        );
    };

    const closeFilterDialog = () => {
        setIsFilterOpen(false);
    };

    const showFilterDialog = () => {
        setIsFilterOpen(true);

        setTimeout(() => {
            document.getElementById('beamer-filter').value = activeFilters.beamer == null ? "" : (activeFilters.beamer ? "true" : "false");
            document.getElementById('seats-filter').value = activeFilters.seats;
            document.getElementById('computers-filter').value = activeFilters.computers;
        }, 0);
    };

    const showAddRoomDialog = () => {
        setIsAddRoomOpen(true);

        setTimeout(() => {
            setErrorMessage('');
            document.getElementById('add-room-name').value = '';
            document.getElementById('add-room-beamer').value = 'false';
            document.getElementById('add-room-seats').value = '0';
            document.getElementById('add-room-computers').value = '0';
        }, 0);
    }

    const submitFilterDialog = () => {
        const beamerValue = document.getElementById("beamer-filter").value;
        const seatsValue = document.getElementById("seats-filter").value;
        const computersValue = document.getElementById("computers-filter").value;

        setActiveFilters({
            beamer: beamerValue === 'true' ? true : (beamerValue === 'false' ? false : null),
            seats: seatsValue,
            computers: computersValue,
        });

        setIsFilterOpen(false);
    };

    const submitAddRoomDialog = () => {
        const nameValue = document.getElementById("add-room-name").value;
        const beamerValue = document.getElementById("add-room-beamer").value;
        const seatsValue = document.getElementById("add-room-seats").value;
        const computersValue = document.getElementById("add-room-computers").value;

        var success = false
        var responseText = ''

        fetch('/api/rooms/add', {
            method: 'PUT',
            headers: {
                roomname: nameValue,
                beamer: beamerValue,
                seats: seatsValue,
                computers: computersValue
            }
        })
        .then(async response => {
            if (response.status == 200) {
                success = true
                await reloadRooms();
            } else {
                responseText = (await response.json()).message
            }
        })
        .catch(error => {
            responseText = 'Es ist ein unerwarteter Fehler aufgetreten! Bitte kontaktieren Sie einen Systemadministrator.';
        })
        .finally(() => {
            if (success) {
                setIsAddRoomOpen(false);
            } else {
                setErrorMessage(responseText);
            }
        });
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (isFilterOpen)
                submitFilterDialog();
            else if (isAddRoomOpen)
                submitAddRoomDialog();
        } else if (e.key === 'Escape') {
            if (isFilterOpen)
                closeFilterDialog();
            else if (isAddRoomOpen)
                setIsAddRoomOpen(false);
        }
    };

    const deleteRoom = async (uid) => {
        try {
            const response = await fetch('/api/rooms/delete', {
                method: 'DELETE',
                headers: {
                    roomuid: uid
                }
            });

            if (response.status == 200)
                await reloadRooms();
        } catch(err) {
            alert("Beim Löschen des Raumes ist ein unerwarteter Fehler aufgetreten!");
        }
    }

    const roomAttributes = [
        {
            name: 'beamer',
            display: 'Beamer',
            isBool: true
        },
        {
            name: 'seats',
            display: 'Sitzplätze',
            isBool: false
        },
        {
            name: 'computers',
            display: 'Computer',
            isBool: false
        }
    ]

    return (
        <div onKeyDown={handleKeyDown}>
            <div className="flex flex-wrap flex-row mb-4 gap-4">
                <button onClick={showFilterDialog} className="border shadow bg-zinc-50 rounded-lg p-2">Filtern</button>
                <button onClick={showAddRoomDialog} className="border shadow bg-zinc-50 rounded-lg p-2 ">Hinzufügen...</button>
            </div>

            {isFilterApplied() && (
                <div className="text-sm mb-4">
                    <strong>Aktuelle Filter: </strong>
                    {activeFilters.beamer !== null && `Beamer: ${activeFilters.beamer ? 'Ja' : 'Nein'}, `}
                    {activeFilters.seats && `Sitzplätze ≥ ${activeFilters.seats}, `}
                    {activeFilters.computers && `Computer ≥ ${activeFilters.computers}`}
                </div>
            )}

            {isFilterOpen && (
                <div
                    className="z-40 fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center"
                    tabIndex="0"
                >
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Filtereinstellungen</h2>
                        <div className="mb-4">
                            <label className="block mb-1">Beamer:</label>
                            <select id="beamer-filter" className="border p-1 rounded w-full">
                                <option value="">Keine Auswahl</option>
                                <option value="true">Ja</option>
                                <option value="false">Nein</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Mindestanzahl Sitzplätze:</label>
                            <input
                                type="text"
                                id="seats-filter"
                                placeholder="Anzahl"
                                className="border p-1 rounded w-full"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Mindestanzahl Computer:</label>
                            <input
                                type="text"
                                id="computers-filter"
                                placeholder="Anzahl"
                                className="border p-1 rounded w-full"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={closeFilterDialog}
                                className="bg-gray-300 hover:bg-gray-400 rounded px-4 py-2 mr-2"
                            >
                                Abbrechen
                            </button>
                            <button
                                onClick={submitFilterDialog}
                                className="bg-blue-500 hover:bg-blue-600 text-white rounded px-4 py-2"
                            >
                                Anwenden
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isAddRoomOpen && (
                <div
                    className="z-40 fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center"
                    tabIndex="0"
                >
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Raum hinzufügen</h2>
                        {errorMessage && (
                            <div className="text-red-500 mb-4 text-center">{errorMessage}</div>
                        )}
                        <div className="mb-4">
                            <label className="block mb-1">Bezeichnung:</label>
                            <input
                                type="text"
                                id="add-room-name"
                                placeholder="Name"
                                className="border p-1 rounded w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Beamer:</label>
                            <select id="add-room-beamer" className="border p-1 rounded w-full">
                                <option value="false">Nein</option>
                                <option value="true">Ja</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Anzahl Sitzplätze:</label>
                            <input
                                type="text"
                                id="add-room-seats"
                                placeholder="Anzahl"
                                className="border p-1 rounded w-full"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Anzahl Computer:</label>
                            <input
                                type="text"
                                id="add-room-computers"
                                placeholder="Anzahl"
                                className="border p-1 rounded w-full"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setIsAddRoomOpen(false)}
                                className="bg-gray-300 hover:bg-gray-400 rounded px-4 py-2 mr-2"
                            >
                                Abbrechen
                            </button>
                            <button
                                onClick={submitAddRoomDialog}
                                className="bg-blue-500 hover:bg-blue-600 text-white rounded px-4 py-2"
                            >
                                Hinzufügen
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex flex-wrap gap-4">
                {filteredRaeume.map((room, roomIndex) => (
                    <div key={roomIndex} className="border rounded-lg p-6 w-full max-w-xs bg-white shadow-md relative">
                        <button
                            onClick={() => deleteRoom(room.uid)}
                            className="absolute top-2 right-2 text-2xl text-red-600 hover:text-red-800 z-10"
                        >
                            &times;
                        </button>
                        <h3 className="text-xl font-semibold mb-2">
                            {room.roomName}
                        </h3>
                        <div className="flex flex-wrap gap-1">
                            {roomAttributes.map((attr, attrIndex) => (
                                <span
                                    key={attrIndex}
                                    className="border rounded-full px-2 py-1 text-xs font-semibold"
                                >
                                    {attr.display}: {attr.isBool ? (room[attr.name] ? 'Ja' : 'Nein') : room[attr.name]}
                                </span>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Raeume;
